import React from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { Link } from 'react-router'
import Connect from '../helpers/Connect'
import Utils from '../helpers/Utils'
import { CLabel } from './CLabel'

export default Connect(({ router, defaultPath, locationHistory, previousForm, isSubSearch, initSearchForm, search }) => {

    defaultPath = Utils.getUrlByPathname(defaultPath || '/home')
    const previousUrl = Utils.getUrlByPathname(Utils.previousPath(locationHistory));

    const shouldGoBack = locationHistory.length > 0 && previousUrl
    const { t } = useTranslation()

    const goBack = () => {
        console.log(router.getCurrentLocation().pathname)
        if (previousForm) {
            initSearchForm(previousForm)
            if (isSubSearch) {
                search(0)
            }
        }

        if (!isSubSearch) {
            router.goBack()
        }
    }

    const linkParams = {
        to: shouldGoBack ? null : defaultPath.url,
        onClick: shouldGoBack ? goBack : null
    }

    const key = isSubSearch && 'search.results' || (shouldGoBack ? previousUrl.key : defaultPath.key)
    return <Trans i18nKey="property.back" values={{ backToPage: t(`property.back.to.${key}`) }}>
        <Link {...linkParams} className="btn btn-back">
            <i className="fa fa-arrow-left icon-left"></i>
            back
        </Link>
        <span className="nowrap"></span>
    </Trans>

},
    {
        dispatch: (dispatch) => ({
            initSearchForm: (form) => dispatch({ type: 'INIT_SEARCH_FORM', defaultValues: form, searchType: Const.SEARCH_TYPE_PROPERTIES, fullReload: true }),
            search: (page, flush) => dispatch({ type: 'SEARCH', page: page, target: "Results", flush: flush })
        }),
        state: (state) => ({
            locationHistory: state.app.locationHistory,
            previousForm: state.searchForm.previousForm,
            isSubSearch: state.searchForm.isSubSearch
        })
    })
