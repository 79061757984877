import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import Connect from "../../helpers/Connect";
import { isContentAdmin } from "../../helpers/UserUtils";
import { AccessRestriction } from "../AccessRestriction";
import { CLabel } from "../CLabel";
import Utils from '../../helpers/Utils'


export default withTranslation()(Connect(({ title, createdById, createdByName, isAliasEnabled, error, value, isLink, isShared, weeklyDigest, weeklyDigestShared, onChange, onSave, user, t, toggleEditSavedSearchPopup }) => {

  const [name, setName] = useState(value)
  const [isSharedState, setShared] = useState(isShared || false)
  const [weeklyDigestState, setWeeklyDigest] = useState(weeklyDigest || false)
  const [weeklyDigestSharedState, setWeeklyDigestShared] = useState(weeklyDigestShared || false)

  const close = () => {
    toggleEditSavedSearchPopup()
  }

  const onSaveChanges = () => {
    onChange({
      target: {
        value: name,
        isShared: isSharedState,
        weeklyDigest: weeklyDigestState,
        weeklyDigestShared: weeklyDigestSharedState
      }
    }, true, false)
    onSave()
  }


  useEffect(() => {
    const onClose = (e) => {
      return (e && e.which == 27 && close())
    }
    document.querySelector('body').addEventListener('keyup', onClose)
    document.querySelector('body').addEventListener('orientationchange', close)
    return () => {
      document.querySelector('body').removeEventListener('keyup', onClose)
      document.querySelector('body').removeEventListener('orientationchange', close)
    }
  }, []);

  const isOwn = isLink || !createdById || createdById == user.id
  return (
    <div className="modal open">
      <div className="modal-header-mobile show-mobile">
        <a className="btn btn-back close-modal" onClick={close}><span className="upper"><CLabel k="settings.custom.popup.close" /></span></a>
        <div className="title-page"><CLabel k={title} /></div>
      </div>
      <div className="modal-dialog modal-sm">
        <div className="modal-content">
          <div className="modal-header">
            <div className="modal-title" style={{ textAlign: 'center' }}>
              <div className="name-block small upper"><CLabel k={title} /></div>
            </div>
            {!isOwn && (isContentAdmin(user, isAliasEnabled)) &&
              <div className="modal-title" style={{ margin: '15px 0px 5px 0px' }}>
                <div className="small"><b><CLabel k="results.fast.filter.save.new.saved.search.note.label" /></b> <CLabel k="results.fast.filter.save.new.saved.search.note.contentadmin" param={{ fullName: createdByName }} /></div>
              </div>
            }
          </div>
          <div className="modal-body">
            <div className="form">
              <div className="box-control">
                <div className="label grey upper" style={{ marginBottom: '10px' }}><CLabel k="results.fast.filter.searh.name.label" /></div>
                <input type="text" name="value" style={{ width: '100%' }}
                  className="form-control control-middle"
                  onChange={(e) => setName(e.target.value)}
                  disabled={!isOwn}
                  value={name}
                  placeholder={t("results.fast.filter.searh.name.placeholder")} />
                {error && <div className="message-error under">{error}</div>}
                {Utils.hasFeature(user.client, "CustomDigest") && <div className={"box-control box-checkbox middle" + (isOwn ? "" : " disabled")} style={{ paddingTop: "10px" }}>
                  <input type="checkbox" name="weeklyDigest" id="weeklyDigest" onChange={(e) => setWeeklyDigest(e.target.checked)} checked={weeklyDigestState} /><label htmlFor="weeklyDigest"><CLabel k="results.fast.filter.save.saved.search.digest.track" /></label>
                </div>}
                {(isContentAdmin(user, isAliasEnabled) || isOwn) && (
                  <div className="box-control box-checkbox middle" style={{ marginTop: "10px" }}>
                    <input type="checkbox" name="isShared" id="sharedSearch" onChange={(e) => setShared(e.target.checked)} checked={isSharedState} /><label htmlFor="sharedSearch"><CLabel k={user.businessGroup && "results.fast.filter.save.new.saved.search.share.businessgroup" || "results.fast.filter.save.new.saved.search.share.label"} /></label>
                  </div>
                )}
                {Utils.hasFeature(user.client, "CustomDigest") && (isContentAdmin(user, isAliasEnabled)) && (
                    <div className={"box-control box-checkbox middle aligned" + (isSharedState && " " || " disabled")} style={{ paddingLeft: "60px", marginTop: "10px" }}>
                      <input type="checkbox" name="weeklyDigestShared" id="weeklyDigestShared" onChange={(e) => setWeeklyDigestShared(e.target.checked)} checked={weeklyDigestSharedState} /><label htmlFor="weeklyDigestShared"><CLabel k={user.businessGroup && "results.fast.filter.save.saved.search.digest.shared.businessgroup" || "results.fast.filter.save.saved.search.digest.shared.label"} /></label>
                    </div>
                    )
                }
              </div>
            </div>
          </div>
          <div className="modal-footer hide-mobile">
            <div className="clearfix">
              <div className="box-btn box-right">
                <a className="btn btn-grey btn-sm close-modal" onClick={close}><span className="upper"><CLabel k="settings.custom.popup.cancel" /></span></a>
                <AccessRestriction><a className="btn btn-red btn-sm close-modal" onClick={onSaveChanges}><span className="upper"><CLabel k="save" /></span></a></AccessRestriction>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer-mobile show-mobile">
        <a className="btn btn-grey btn-normal close-modal" onClick={close}><span className="upper"><CLabel k="settings.custom.popup.cancel" /></span></a>
        <AccessRestriction><a className="btn btn-red btn-normal close-modal" onClick={onSaveChanges}><span className="upper"><CLabel k="save" /></span></a></AccessRestriction>
      </div>
    </div>
  )
},
  {
    dispatch: (dispatch) => ({
      toggleEditSavedSearchPopup: () => dispatch({ type: 'TOGGLE_POPUP_EDIT_SAVED_SEARCH' })
    }),

    state: (state) => ({ 
      user: state.session?.user,
      isAliasEnabled: state.session?.isAliasEnabled
     })
  }
))
